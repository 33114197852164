:root {
  --swiper-pagination-color: #F30080;
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(255, 255, 255, 0.5);
  --swiper-pagination-progressbar-size: 2px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-inactive-color: #000;
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;

  --swiper-navigation-size: 32px !important;
  --swiper-navigation-top-offset: 85%;
  --swiper-navigation-sides-offset: 10px;
  --swiper-navigation-color: #C9DA2B;
}

/* .swiper-horizontal>.swiper-pagination-progressbar {
  background: white;
} */